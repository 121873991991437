<template>
    <div class="Myclass">
        <div class="breadcrumb">
            我的课堂
        </div>
        <div class="form-inline clearfix">
            <el-button type="primary" size="small" @click="dialogVisible=true">加入课堂</el-button>
            <div class="fr">
                <div class="form-group">
                    <span></span>
                    <span>状态：
                        <el-select v-model="states" placeholder="请选择状态" size="small" @change="selecrstate(states)">
                            <el-option label="全部" :value="0"></el-option>
                            <el-option label="未开课" :value="1"></el-option>
                            <el-option label="已开课" :value="2"></el-option>
                            <el-option label="已结课" :value="3"></el-option>
                        </el-select>
                    </span>
                    <!-- <span>状态：</span> -->
                    <span>
                    年份：
                        <el-select v-model="year" placeholder="请选择年份" size="small" @change="selecrstates(year)">
                            <el-option label="全部" :value="0"></el-option>
                            <el-option label="2021年" :value="2021"></el-option>
                            <el-option label="2022年" :value="2022"></el-option>
                        </el-select>
                    </span>
                    <span>
                        <el-input v-model="search" placeholder="请输入关键字" size="small" @keyup.enter.native="enterSearch($event)"></el-input>
                    </span>
                    <span>
                        <el-button type="primary" size="small" @click="query()">查询</el-button>
                    </span>
                    <span>
                        <el-button type="warning" size="small" @click="reset()">重置</el-button>
                    </span>
                </div>
            </div>
            <hr style="margin:10px 0;">
        </div>
        <div class="form_contents">
            <el-row :gutter="25">
                <el-col 
                    :xs="24" 
                    :md="12" 
                    :lg="8" 
                    :xl="6"
                    v-for="(item,index) in roomList" :key="index"
                >
                    <div class="blog_box">
                        <div class="blog_box_cover">
                            <span class="fl">
                                <span>课堂码：</span>
                                <span class="c_code">{{item.code}}</span>
                            </span>
                            <!-- <span class="fr">
                                <span>授课教师：</span>
                                <span class="c_code">{{item.teacherName}}</span>
                            </span> -->
                        </div>
                        <div class="post-info">
                            <div class="meta-container">
                                <h4 class="text-overflow m-t-0">{{item.name}}</h4>
                                <div class="font-13"><span class="meta">{{item.createTime}}</span></div>
                            </div>
                            <div class="row_cover clearfix meta-container">
                                <!-- <span class="fl">项目数量：{{item.projects}}</span> -->
                                <span class="fl">总人数：{{item.users}}</span>
                                <span class="fl">小组数：{{item.teams}}</span>
                            </div>
                            <div class="row_buttun clearfix">
                                <el-button type="info" plain class="fl" size="small" @click="panel(item)">更换小组</el-button>
                                <el-button type="primary" plain class="fr" size="small" @click="Inroom(item)">进入课堂</el-button>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col 
                    :span="24"
                    v-show="roomList.length==0"
                    class="elcol_col"
                >
                    <div class="elcol_div">
                        <div class="default-wrappers" >
                            <img src="./../../assets/img/bookmark.svg" alt="">
                            <p><span>未加入课堂</span></p>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 弹窗 -->
        <div class="joinclass">
            <el-dialog
                title="加入课堂"
                :visible.sync="dialogVisible"
                :show-close="false"
                width="450px"
                :before-close="handleClose">
                    <i class="el-icon-close closeis" @click="closei('ruleForm')"></i>
                    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" @submit.native.prevent>
                        <el-form-item label="课堂码：" prop="code">
                            <el-input v-model="ruleForm.code" placeholder="请输入六位课堂码" @keyup.enter.native="enterInput($event)"></el-input>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button size="small" @click="closei('ruleForm')">取 消</el-button>
                        <el-button size="small" type="primary" @click="addclass('ruleForm')">确 定</el-button>
                    </span>
                    <el-dialog
                        width="450px"
                        title="选择小组"
                        custom-class="joinclass"
                        :visible.sync="innerVisible"
                        :before-close="handleClose"
                        :show-close="false"
                        append-to-body>
                        <i class="el-icon-close closeis" @click="closeis('ruleForms')"></i>
                        <el-form ref="ruleForms" :model="ruleForms" :rules="rules" @submit.native.prevent>
                            <el-form-item label="选择小组：" prop="id">
                                <el-select v-model="ruleForms.id" placeholder="请选择要加入的组">
                                    <el-option
                                        v-for="(item,index) in teamList"
                                        :key="index"
                                        :label='item.name'
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                        <span slot="footer" class="dialog-footer">
                            <el-button size="small" @click="closeis('ruleForms')">取 消</el-button>
                            <el-button size="small" type="primary" @click="addteam('ruleForms','ruleForm')">确 定</el-button>
                        </span>
                    </el-dialog>
            </el-dialog>
            <el-dialog
                title="更换小组"
                :visible.sync="dialogVisibleForm"
                :show-close="false"
                width="450px"
                :before-close="handleClose">
                    <i class="el-icon-close closei" @click="closei('Form')"></i>
                    <el-form ref="Form" :model="Form" :rules="rules" @submit.native.prevent>
                        <el-form-item label="课程名称：" prop="name">
                            <el-input v-model="Form.name" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="课堂码：">
                            <el-input v-model="Form.code" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="选择小组：" prop="teamName">
                            <el-select v-model="Form.teamName" placeholder="请选择要加入的组">
                                <el-option
                                    v-for="(item,index) in listTeam"
                                    :key="index"
                                    :label='item.name'
                                    :value="item.name">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button size="small" @click="dialogVisibleForm = false">取 消</el-button>
                        <el-button size="small" type="primary" @click="deter('Form')">确 定</el-button>
                    </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            teamList:[],
            ruleForm:{
                code:''
            },
            ruleForms:{
                id:''
            },
            Form:{
                code:''
            },
            rules:{
                code:[
                    { required: true, message: '请输入六位课堂码'},
                    {pattern:/^\d+$/, message:'课堂码必须为数字值'}
                ],
                id:[
                    { required: true, message: '请选择小组', trigger:'change'}
                ],
                teamName:[
                    { required: true, message: '请选择小组', trigger:'change'}
                ]
            },
            search:'',
            roomList:[],
            dialogVisible:false,
            dialogVisibleForm:false,
            innerVisible:false,
            listTeam:[],
            states:0,
            year:0
        }
    },
    methods:{
        getapp(){
            this.axios.StuCourseList({
                token:this.$store.state.token,
                state:this.states,
                year:this.year,
                search:this.search
            }).then(res=>{
                if(res.data.code==0){
                    this.roomList = res.data.data
                }else{
                    this.$message.error(res.data.message)
                    this.roomList = []
                }
            }).catch(err=>{

            })
        },
        //加入课堂
        addclass(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.StuCoursecode({
                        token:this.$store.state.token,
                        code:this.ruleForm.code
                    }).then(res=>{
                        if(res.data.code==0){
                            if(res.data.data.type==1){
                                this.axios.StuCoursejoin({
                                    token:this.$store.state.token,
                                    code:this.ruleForm.code,
                                }).then(res=>{
                                    this.dialogVisible= false
                                    this.$refs[formName].resetFields();
                                    this.getapp()
                                }).catch(err=>{

                                })
                            }else{
                                this.teamList = res.data.data.teams
                                this.innerVisible = true
                            }
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }
            })
        },
        // 选择小组
        addteam(formName,ruleForm){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.StuCoursejoin({
                        token:this.$store.state.token,
                        code:this.ruleForm.code,
                        id:this.ruleForms.id
                    }).then(res=>{
                        if(res.data.code==0){
                            this.innerVisible = false
                            this.dialogVisible= false
                            this.$refs[formName,ruleForm].resetFields();
                            this.getapp()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }
            })
        },
        // 进入课堂
        Inroom(item){
            if(item.state==1){
                this.$message.error('老师未开课无法进入课堂')
            }else{
                this.$store.dispatch("cla_id",item.id)   //课堂id
                this.$router.push({name:'Cognitivecomb'})
            }
        },
        //更换小组
        panel(item){
            if(item.state==1){
                this.axios.StuCourseteam({
                    token:this.$store.state.token,
                    id:item.id
                }).then(res=>{
                    if(res.data.code==0){
                        this.listTeam = res.data.data
                        this.dialogVisibleForm = true
                        this.Form = item
                    }else{
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{

                })
            }else{
                this.$message.warning('课堂已开课，无法更换小组')
            }
        },
        deter(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.listTeam.map(item=>{
                        if(this.Form.teamName==item.name){
                            this.id = item.id
                        }
                    })
                    this.axios.StuCoursswitchTeam({
                        token:this.$store.state.token,
                        cId:this.Form.id,
                        id:this.id
                    }).then(res=>{
                        if(res.data.code==0){
                            this.dialogVisibleForm = false
                            this.getapp()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }
            })
        },
        // 通过状态查询
        selecrstate(val){
            this.states = val
            this.getapp()
        },
        // 通过年份查询
        selecrstates(val){
            this.year = val
            this.getapp()
        },
        // 回车事件查询
        enterSearch(e){
            if (e.which == 13 || e.charCode == 13 || e.keyCode == 13) {
                this.query();
            }
        },
        enterInput(e){
            if (e.which == 13 || e.charCode == 13 || e.keyCode == 13) {
                this.addclass('ruleForm');
            }
        },
        // 关键字查询
        query(){
            this.search = this.search
            this.getapp()
        },
        // 重置
        reset(){
            this.search = ''
            this.states = 0
            this.year = 0
            this.getapp()
        },
        handleClose(done){
            
        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.dialogVisibleForm = false
            this.$refs[formName].resetFields();
        },
        closeis(formName){
            this.innerVisible = false
            this.$refs[formName].resetFields();
        }
    },
    beforeDestroy () {
        let that = this;
        clearInterval(that.timer);
        that.timer = null;
    },
    destroyed () {
        let that = this;
        clearInterval(that.timer);
        that.timer = null;
    },
    mounted() {
        setTimeout(() => {
            this.getapp()
        }, 500)
        let that = this;
        that.timer = window.setInterval(() => {
            setTimeout(() => {
                that.getapp()
            }, 0)
        },3000)
    }
}
</script>
<style scoped>
@import './../../assets/css/student/Myclass.css';
</style>
<style>
.form_contents .el-row{
    height: 100%;
}
.form-group .el-input__inner{
    width: 180px;
}
.joinclass .el-dialog{
    /* padding: 25px; */
}
.joinclass .el-form-item{
    margin-bottom: 15px;
}
.joinclass .el-dialog__header{
    padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.joinclass .el-dialog__title{
    font-size: 16px;
    font-weight: bold;
    color:#fff;
}
.joinclass .el-dialog__body{
    padding: 20px 40px;
}
.joinclass .el-form-item__label{
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #797979;
}
.joinclass .el-radio-group{
    width: 100%;
}
.joinclass .el-dialog__footer{
    text-align: center;
    padding:0 20px 20px;
}
/* .joinclass .el-dialog__header{
    padding: 0 0 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #eaecee;
    background: #409EFF;
} */
/* .joinclass .el-dialog__title{
    font-size: 14px;
    color: #fff;
}
.joinclass .el-dialog__footer{
    text-align: center;
} */
.joinclass .el-select{
    width: 100%;
}
</style>